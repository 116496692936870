import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import favicon from '../../static/favicon-32x32.png';

const SeoComponent = props => (
  <StaticQuery
    query={detailsQuery}
    render={data => {
      const title = props.title || data.site.siteMetadata.title;
      const description = props.description;
      return (
        <Helmet
          htmlAttributes={{
            lang: 'no'
          }}
          title={"Kredittlisten.no - " + title}
          titleTemplate={props.title ? `%s` : `%s - ${data.site.siteMetadata.title}`}
          link={[
            { rel: 'shortcut icon', type: 'image/svg', href: `${favicon}` }
          ]}
        >
        {description && <meta name="description" content={description}/>}
      </Helmet>
      );
    }}
  />
);

SeoComponent.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: []
};

export default SeoComponent;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
